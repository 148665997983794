<template>
  <div>
    <section class="section is-main-section">
      <title-bar :title-stack="titleStack"/>

      <card-component>
        <div class="content ">
          <div class="txt large">
            <p><span>This declaration was&nbsp;created&nbsp;to clarify under which circumstances we use your personal&nbsp;data&nbsp;and how we protect&nbsp;it. We may&nbsp;on occasion&nbsp;need to update this privacy declaration. Each change we make to this document will be published to our website so you will always know which&nbsp;information&nbsp;we collect, how we use it, and under which&nbsp;circumstances&nbsp;we could share the data&nbsp;with third parties. That is why we ask that you consult this document regularly.&nbsp;</span></p>
            <p><span>TowerEye&nbsp;bvba&nbsp;processes personal&nbsp;data&nbsp;according to this privacy declaration.</span></p>
            <p><span>For further information, questions, or&nbsp;feedback concerning our privacy policy, please contact us via e-mail at&nbsp;<a href="mailto:info@towereye.be">info@towereye.be</a>.&nbsp;</span></p>
            <h5>REASONS FOR DATA PROCESSING</h5>
            <p><span>TowerEye&nbsp;bvba&nbsp;collects and processes the personal&nbsp;data of clients for&nbsp;purposes of&nbsp;client and order&nbsp;management (e.g. client administration,&nbsp;order/delivery follow-up, invoicing,&nbsp;solvency&nbsp;follow-up,&nbsp;and the distribution of marketing and personalized&nbsp;promotional materials).&nbsp;</span></p>
            <h5>LAWFULNESS OF DATA PROCESSING</h5>
            <p><span>Personal&nbsp;data&nbsp;is processed&nbsp;according to&nbsp;Article 6.1. of the General Data Protection Regulation (GDPR):&nbsp;</span></p>
            <p><span>a) With the consent&nbsp;of the data subject</span></p>
            <p><span>b) When processing is necessary to fulfill an&nbsp;agreement</span></p>
            <p><span>c) When processing is necessary to fulfill a&nbsp;legal&nbsp;obligation</span></p>
            <p><span>d) When processing is necessary for the pursuit of our legitimate business interests</span></p>
            <h5>THIRD-PARTY DATA PROCESSING</h5>
            <p><span>If&nbsp;deemed&nbsp;necessary&nbsp;to&nbsp;achieve&nbsp;predetermined&nbsp;goals,&nbsp;the&nbsp;client&rsquo;s&nbsp;personal&nbsp;data&nbsp;will be&nbsp;shared with other organizations&nbsp;within the European Economic&nbsp;Area&nbsp;that are directly or indirectly connected with&nbsp;TowerEye&nbsp;bvba&nbsp;or its partners;&nbsp;TowerEye&nbsp;bvba&nbsp;guarantees that any recipients of shared&nbsp;personal&nbsp;data&nbsp;will take the necessary technical and organizational&nbsp;measures to protect the&nbsp;personal&nbsp;data.</span></p>
            <h5>RETENTION PERIOD</h5>
            <p><span>Personal&nbsp;data&nbsp;processed for client management purposes will be&nbsp;retained for a period&nbsp;sufficient&nbsp;to comply with the legal&nbsp;obligations&nbsp;(e.g.&nbsp;for&nbsp;accounting purposes).</span></p>
            <h5><span><strong>RIGHT TO VIEW, CORRECT, DELETE, RESTRICT, OBJECT TO, AND TRANSFER PERSONAL DATA</strong></span></h5>
            <p><span>Clients&nbsp;will always have the right to&nbsp;view their&nbsp;personal&nbsp;data&nbsp;and can correct it or have it corrected&nbsp;if it is incorrect or incomplete; they can have their personal&nbsp;data&nbsp;deleted&nbsp;or&nbsp;restrict&nbsp;processing of their personal&nbsp;data; they can object to&nbsp;their personal&nbsp;data&nbsp;being processed according to GDPR Article 6.1 (f), including&nbsp;profiling on the basis of those&nbsp;regulations.</span></p>
            <p><span>In addition, clients have the right to request a copy&nbsp;of their&nbsp;personal information&nbsp;(in a structured,&nbsp;commonly acceptable, and&nbsp;machine-readable&nbsp;format)&nbsp;and to have their personal&nbsp;data&nbsp;forwarded to&nbsp;another organization.&nbsp;To exercise&nbsp;the aforementioned rights, clients&nbsp;are&nbsp;asked to direct an e-mail to the following e-mail address:&nbsp;<a href="mailto:info@dncs.be">info@dncs.be</a>.</span></p>
            <h5>DIRECT MARKETING</h5>
            <p><span>Clients have the right to&nbsp;object,&nbsp;free of charge,&nbsp;to the processing of their personal&nbsp;data&nbsp;for direct marketing purposes.</span></p>
            <h5>COMPLAINTS</h5>
            <p><span>Clients have the right to&nbsp;file&nbsp;a complaint&nbsp;with the&nbsp;Belgian Data Protection Authority&nbsp;(Drukpersstraat&nbsp;35, 1000 Brussel &ndash; <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a>).&nbsp;</span></p>
          </div>
        </div>
      </card-component>

    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
export default {
  name: 'PrivacyPolicy',
  components: { TitleBar, CardComponent },
  computed: {
    titleStack () {
      return [
        'Privacy Policy'
      ]
    },

  }
}
</script>
